.msg-snackbar {
    .MuiAlert-message  {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
    }
    .MuiAlert-standardError {
        background: #F3E0E0 !important;
        border-radius: 6px;
        min-height: 50px;
    }
    .MuiAlert-standardSuccess {
        background: #E8F1E5 !important;
        border-radius: 6px;
        min-height: 50px;
    }
    .MuiAlert-standard {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #E8F1E5;
        border-radius: 6px;
        min-height: 50px;
    }
    .MuiAlert-standardSuccess .MuiAlert-icon img {
        width: 22px;
        height: 21px;
    }
    .MuiAlert-standardError .MuiAlert-icon img {
        width: 22px;
        height: 19px;
    }
}
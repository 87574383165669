@mixin fullWidth {
    width: 100%;
    float: left;
}
.HeaderContainer {
    width: 100%;
    padding: 0px 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // position: absolute;
    z-index: 99;
    // background-color: #000;
    border-bottom: solid 1px #E6E6E6;
    @media (max-width: 768px) {
        padding: 15px 15px;
    }
    .LogoContainer {
        @media (max-width: 768px) {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            
        }
        // svg {
        //     height: 40px;
        //     cursor: pointer;
        // }
        img {
            height: 100px;
            cursor: pointer;
        }
    }
    .HeaderMenuContainer {
        .MobileLog {
            display: none;
            @media (max-width: 768px) {
                display: block;
                width: 100%;
                float: left;
                padding: 13px 15px;
                border-bottom: solid 1px #E6E6E6;
                // svg {
                //     height: 40px;
                // }
                img {
                    height: 40px;
                }
            }
        }
        .MuiButton-textPrimary {
            color: #999;
            padding: 13px 6px;
            font-size: 14px;
            font-weight: 400;
            text-transform: unset;
            font-family: 'Josefin Sans', sans-serif;
            line-height: 16px;
            span {
                min-width: 80px;
                text-align: center;
                @media (max-width: 768px) {
                    text-align: left;
                }
            }
            svg {
                display: none;
            }
            @media (max-width: 768px) {
                color: #C58800;
                width: 100%;
                justify-content: flex-start;

                svg {
                    display: block;
                    margin-right: 10px;
                }
            }
            &.ViewCartBtn {
                color: #C58800;
                font-size: 16px;
                font-weight: 700;
                padding: 13px 20px;
                border-radius: 5px;
                border: 1px solid #C58800;
                background-color: #FFF;
                outline: none;
                margin-left: 25px;
                line-height: 16px;
                &.activeBtn {
                    color: #fff;
                    background-color: #C58800;
                }
            }
            &.activeBtn {
                color: #000;
                font-size: 16px;
                font-weight: 500;
                @media (max-width: 768px) {
                    color: #C58800;
                }
            }
        }
    }
    &.homeHeader {
        position: absolute;
        border-bottom: none;
        top: 0;
        @media (max-width: 767px) {
            border-bottom: solid 1px #E6E6E6;
        }
        .HeaderMenuContainer {
            .MuiButton-textPrimary {
                color: #CACACA;
                &.activeBtn {
                    color: #FFF;
                    font-size: 16px;
                    font-weight: 500;
                    @media (max-width: 768px) {
                        color: #C58800;
                    }
                }
                @media (max-width: 767px) {
                    color: #C58800;
                }
                &.ViewCartBtn {
                    color: #C58800;
                }
            }
        }
        .LogoContainer {
            .mobileMenuCart {
                svg {
                    path {
                        stroke: #fff;
                    }
                }
            }
        }
    }
}

.mobileMenuCart {
    display: none;
    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        .MuiButton-textPrimary {
            padding: 0;
            min-width: 40px;
        }
    }
}

.HeaderMenuContainer {
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        height: 100vh;
        bottom: 0;
        background-color: #fff;
        width: 210px;
        align-items: flex-start;
        z-index: 99;
        left: -210px;
        transition: all ease .4s;
        .ViewCartBtn {
            display: none;
        }
    }
    
}
@media (max-width: 768px) {
    .MobileOverLay {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgb(0 0 0 / 82%);
        transform: scale(0);
        // transition: all ease .4s;
    }
.menuOpen {
    overflow: hidden;
    .HeaderMenuContainer {
        left: 0;
        transition: all ease .4s;
    }
    .MobileOverLay {
        transform: scale(1);
        z-index: 98;
        // transition: all ease .4s;
    }
}
}

.dotClass{
    position: absolute;
    right: 15px;
    top: 12px;
}

.dotClassSm {
    position: absolute;
    right: 5px;
    top: 5px;
  }
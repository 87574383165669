@mixin fullWidth {
    width: 100%;
    float: left;
}

.bb-none {
    border-bottom: none !important;
}

@media (max-width: 991px) {
    .mbb-none {
        border-bottom: none !important;
    }
}

.hideWeb {
    display: none !important;

    @media (max-width: 991px) {
        display: block !important;
    }
}

.cart-item-set {
    &.mrow {
        @media (max-width: 991px) {
            flex-direction: row !important;
        }
    }
}

.MuiButton-textPrimary.payBtn {
    @include fullWidth();
    padding: 10px 10px;
    border-radius: 5px;
    background-color: #C58800 !important;
    border: solid 1px #C58800;
    color: #FFF;
    font-family: Bree Serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.MainTitle {
    @include fullWidth();
    padding: 30px 0;
    text-align: center;
    color: #005466;
    font-family: 'Bree Serif', serif;
    font-size: 34px;
    font-weight: 400;
    letter-spacing: -0.34px;

    @media (max-width: 991px) {
        padding: 15px 0;
        font-size: 16px;
    }
}

.FullWidth {
    @include fullWidth();
}

.ItemCard {
    @include fullWidth();
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    // margin-bottom: 30px;
    height: 413px;
    overflow: hidden;

    .ItemCardImg {
        @include fullWidth();
        min-height: 200px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        transition: all ease .3s;
        border-radius: 5px;

        .dishType {
            width: 92px;
            // color: #009506;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 10px 10px;
            border-radius: 5px 0px 0px 0px;
            background: #FFF;
            position: absolute;
            bottom: -18px;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .square {
                width: 12px;
                height: 12px;
                border: solid 2px #009506;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5px;
                border-radius: 2px;

                .dot {
                    content: "";
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                    background-color: #00a912;
                }
            }

            &.nonveg {
                color: #7D0101;

                .square {
                    border: solid 2px #7D0101;

                    .dot {
                        background-color: #7D0101;
                    }
                }
            }
        }
    }

    .ItemCardBody {
        @include fullWidth();
        padding: 15px 10px;

        .title {
            width: 100%;
            color: #002687;
            font-family: 'Bree Serif', serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            padding-top: 10px;
            padding-bottom: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            align-items: flex-start;

            .titleText {
                text-transform: capitalize;
                width: 80%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

            }

            .cost {
                color: #007705;
                font-family: 'Bree Serif', serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.9px;
            }
        }

        .content {
            @include fullWidth();
            color: #555;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.13px;
            margin-bottom: 15px;
            height: 55px;

            .scrollbar-container {
                word-break: break-all;
            }
        }

        .BtnContainer {
            @include fullWidth();
            padding: 0 15px;

            .MuiButton-textPrimary {
                width: 100%;
                border-radius: 5px;
                border: 1px solid #002687;
                background: #FFF;
                text-transform: inherit;
                color: #002687;
                font-family: 'Josefin Sans', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                padding: 6px 2px;
            }

            .MuiButtonGroup-root {
                width: 100%;
                border-radius: 5px;
                border: 1px solid #002687;
                display: flex;
                align-items: center;
                justify-content: center;

                .MuiButtonBase-root {
                    border: none;
                    font-size: 24px;
                    color: #002687;
                    font-family: 'Josefin Sans', sans-serif;
                    height: 30px;

                    &.itemCountBtn {
                        color: #002687;
                        font-family: 'Josefin Sans', sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                }
            }
        }

        .AddExtraContainer {
            @include fullWidth();
            display: none;

            .AddExtraTitle {
                @include fullWidth();
                padding: 5px 0;
                color: #555;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px;
                /* 128.571% */
                letter-spacing: -0.14px;
            }

            .extraItemsSetContainer {
                @include fullWidth();
                min-height: 193px;
                padding-top: 5px;
                transition: all ease .3s;
                ;

                .extraItemsSet {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .MuiFormControlLabel-root {
                        .MuiCheckbox-colorPrimary {
                            padding: 7px 10px;

                            .MuiSvgIcon-root {
                                font-size: 17px;
                                color: #C5C5C5;
                                ;
                            }
                        }

                        .MuiTypography-root {
                            color: #555;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.14px;
                            font-family: 'Josefin Sans', sans-serif;
                        }

                        .MuiButtonBase-root {
                            &.Mui-checked {
                                .MuiSvgIcon-root {
                                    color: #C58800;
                                }
                            }
                        }
                    }

                    .itemsCost {
                        color: #313131;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: -0.14px;
                    }

                    &:last-child {
                        .MuiFormControlLabel-root {
                            align-items: flex-end;

                            .MuiCheckbox-colorPrimary {
                                padding-bottom: 0
                            }
                        }
                    }
                }
            }
        }
    }

    &.AddItems {
        .ItemCardImg {
            min-height: 120px;
            transition: all ease .3s;
        }

        .CardBodyDesc {
            display: none;
        }

        .AddExtraContainer {
            display: block;
        }
    }
}

.HeaderFilterContainer {
    flex: 1;
    display: flex;
    align-items: center;

    .SerchbarInput {
        @include fullWidth();
        height: 46px;
        border-radius: 5px;
        border: 1px solid #E6E6E6;
        background: #FFF;
        margin: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .MuiInputLabel-root {
            color: #757575;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.13px;
            font-family: Josefin Sans;

            &.MuiInputLabel-shrink {
                transform: translate(14px, -9px) scale(1);
            }
        }

        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
            padding: 10.5px 14px;
            color: #000;
            font-family: Josefin Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.13px;
        }

        .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
            border: none;
        }
    }

    .SearchDropDownBtn,
    .SearchDropDownBtn:hover {
        width: 60px;
        min-width: 60px;
        height: 46px;
        border: 1px solid #E6E6E6;
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-color: #fff;
    }
}

.FilterDropdown {
    .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
        width: 240px;
        border-radius: 10px;
        background: #F6EDD9;
        box-shadow: 3px 4px 8px 0px rgba(246, 237, 217, 0.20), -3px -2px 6px 0px rgba(246, 237, 217, 0.20);
        margin-top: 20px;

    }

    .css-6hp17o-MuiList-root-MuiMenu-list {
        padding: 0;
    }

    .css-dmmspl-MuiFormGroup-root {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 20px 20px;
        padding-bottom: 0;
        border-bottom: solid 1px #fff;

        .PrivateSwitchBase-input {
            width: 15px;
            height: 15px;
        }

        .css-1hbvpl3-MuiSvgIcon-root,
        .css-11zohuh-MuiSvgIcon-root {
            width: 15px;
            height: 15px;
            color: #C58800;
        }

        .css-j204z7-MuiFormControlLabel-root {
            width: 50%;
            margin: 0;
            margin-bottom: 20px;

            .MuiRadio-colorPrimary {
                padding: 0;
                margin-right: 8px;

                &.Mui-checked {
                    &+.MuiTypography-root {
                        color: #000;
                        font-weight: 500;
                    }
                }
            }

            .MuiTypography-root {
                color: #555;
                font-family: Josefin Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: -0.14px;
                text-align: left !important;
            }
        }
    }

    .dropdownFooter {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 20px;
        padding-top: 15px;

        .MuiButton-containedPrimary {
            color: #C58800;
            padding: 9px 20px;
            font-family: Josefin Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.14px;
            text-transform: unset;
            border-radius: 5px;
            border: 1px solid #C58800;
            background: #F6EDD9;
            box-shadow: none;

            &.applyBtn {
                background: #C58800;
                color: #fff;
            }
        }
    }
}

// Landing Page Style Start
.LandingPageContainer {
    @include fullWidth();

    .BannerContainer {
        @include fullWidth();
        display: flex;
        align-items: center;
        padding-left: 80px;
        position: relative;
        min-height: 580px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;

        .payBtn {
            padding: 8px 10px;
            margin-top: 15px;
            text-transform: inherit;
        }

        @media (max-width: 991px) {
            padding-left: 15px;
            min-height: 270px;
            padding-top: 70px;

            .payBtn {
                width: 70%;
                display: block;
                padding: 7px 10px;
                font-size: 14px;
                text-transform: unset;
                margin-top: 12px;
            }
        }
    }

    .BannerImage {
        position: absolute;
        right: 0;
        bottom: -60px;


        @media (max-width: 1250px) {
            img {
                width: 470px;
                height: 470px;
            }
        }


        @media (max-width: 400px) {
            img {
                width: 150px;
                height: 150px;
                position: absolute;
                right: 0;
                bottom: -35px;
            }
        }

        @media (max-width: 991px) {
            img {
                width: 300px;
                height: 300px;
                position: absolute;
                right: 0;
                bottom: -50px;
            }
        }

        @media (max-width: 761px) {
            img {
                width: 200px;
                height: 200px;
                position: absolute;
                right: 0;
                bottom: -10px;
            }
        }
    }

    .BannerContent {
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            flex-direction: column;
            padding: 0 15px;
            align-items: flex-start;
            position: relative;
            z-index: 1;
        }

        .BannerContentTitle {
            flex: 1.2;
            color: #FFF;
            font-size: 60px;
            font-style: normal;
            font-weight: 400;
            line-height: 60px;
            letter-spacing: -0.6px;
            padding-right: 30px;
            border-right: solid 1px #fff;
            font-family: 'Bree Serif', serif;

            @media (max-width: 991px) {
                font-size: 18px;
                line-height: 16px;
                border-right: none;
            }

            @media (max-width: 500px) {
                font-size: 17px;
                line-height: 14px;
                border-right: none;
            }
        }

        .BannerContentText {
            flex: 1.5;
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            /* 111.111% */
            letter-spacing: -0.18px;
            padding-left: 30px;
            display: flex;
            flex-direction: column;

            @media (max-width: 991px) {
                font-size: 12px;
                line-height: 14px;
                padding-left: 0;
                padding-top: 12px;
            }

            @media (max-width: 600px) {
                font-size: 11px;
                line-height: 14px;
                padding-left: 0;
                padding-top: 12px;
                width: 370px;
            }

            @media (max-width: 600px) {
                font-size: 11px;
                line-height: 14px;
                padding-left: 0;
                padding-top: 12px;
                width: 230px;
            }

            @media (max-width: 600px) {
                font-size: 11px;
                line-height: 14px;
                padding-left: 0;
                padding-top: 12px;
                width: 230px;
            }

            @media (max-width: 410px) {
                font-size: 10px;
                line-height: 14px;
                padding-left: 0;
                padding-top: 12px;
                width: 170px;
            }
        }
    }
}

.OurMenuContainer {
    @include fullWidth();
    background-color: #F6EDD9;
    margin-bottom: 30px;

    @media (max-width: 991px) {
        padding: 0 15px;
    }

    .OurMenuContentSet {
        position: relative;
        align-items: center;
        display: flex;

        @media (max-width: 991px) {
            flex-direction: column;
        }

        .OurMenuContent {
            color: #555;
            text-align: center;
            font-family: Josefin Sans;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.14px;
        }
    }

    .ExploreAllBtn {
        position: absolute;
        right: 80px;
        color: #005466;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: -0.14px;
        text-transform: capitalize;
        font-family: Josefin Sans;

        svg {
            margin-left: 5px;
        }

        @media (max-width: 991px) {
            position: relative;
            right: 0;
            margin-top: 12px;
        }
    }
}

.FilterBtnSet {
    @include fullWidth();
    //   margin: 0 80px 0 80px;
    background-color: #FFFCF4;
    margin-top: 60px;
    // padding: 30px 0 0px 0px;
    margin-bottom: 60px;
    width: 100%;

    @media (max-width: 550px) {
        margin-top: 40px;
        margin-bottom: 40px;
    }


    .slick-slider {
        // border-bottom: 1px solid #E1E1E1;
    }

    @media (max-width: 991px) {
        padding: 15px 0;
    }

    @media (max-width: 550px) {
        padding: 0;
    }

    .MuiFormGroup-root {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        // padding-bottom: 5px;


        // border-bottom:1px solid black ;


        .MuiFormControlLabel-root {
            margin-right: 0;
            padding: 11px 20px;

            @media (max-width: 991px) {
                padding: 0;
                padding-bottom: 10px;
            }

            .MuiRadio-root {
                display: none;

                &.Mui-checked {
                    .MuiButtonBase-root {
                        border-bottom: 4px solid #C58800;
                    }

                    &+.MuiTypography-root {
                        // background-color: #C58800;
                        // padding-left: 12px;
                        color: #C58800;
                        font-weight: 700;
                        font-size: 14px;
                        // border-bottom: 3px solid #C58800;
                        padding-bottom: 10px;
                        position: relative;



                    }
                }
            }

            .MuiTypography-root {

                color: #555555;
                // border-radius: 5px;
                // border: 1px solid #C58800;
                // background: #F6EDD9;
                font-family: Josefin Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                letter-spacing: -0.14px;
                margin-right: 40px;
                white-space: nowrap;

                @media (max-width: 991px) {
                    margin-right: 10px;
                }
            }

        }
    }

    .slick-initialized {
        .slick-slide {
            width: auto !important;
        }
    }
}

.MenuItemsSlider {
    @include fullWidth();
    padding: 0 80px;
    overflow: hidden;
    // padding-bottom: 50px;

    @media (max-width: 991px) {
        padding-left: 0;
        padding-right: 0;
    }

    .slick-slide {
        padding: 0 15px;

        @media (max-width: 991px) {
            padding: 0;
        }

        &:first-child {
            padding-left: 0;
        }
    }
}

.OurStoryContainer {
    @include fullWidth();
    padding: 0px 80px;
    padding-bottom: 20px;

    @media (max-width: 766px) {
        padding: 15px 15px;
        margin-top: 30px;
    }

    @media (max-width: 991px) {
        padding: 15px 15px;
        margin-top: 30px;

        .MainTitle {
            text-align: center;
            padding-top: 0;
        }
    }

    .OurStoryImgContainer {
        @include fullWidth();

        img {
            width: 100%;
            height: auto;
        }

    }

    .OurStoryContent {
        @include fullWidth();

        .MainTitle {
            text-align: left;
        }

        p {
            @include fullWidth();
            margin: 0;
            margin-bottom: 15px;
            color: #555;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 35px;
            /* 125% */
            letter-spacing: -0.16px;

            @media (max-width: 767px) {
                font-size: 12px;
                margin-bottom: 12px;
            }
        }
    }

    .css-1i6hukq-MuiGrid-root {
        align-items: flex-end;
        display: flex;
    }
}

.NewFoodsContainer {
    @include fullWidth();
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    .NewFoodsHeaderContainer {
        @include fullWidth();
        padding: 30px 30px;
        color: #FFF;
        text-align: center;
        font-family: 'Bree Serif', serif;
        font-size: 34px;
        font-weight: 400;
        letter-spacing: -0.34px;

        @media (max-width: 991px) {
            padding: 15px 15px;
            font-size: 16px;
            line-height: 19px;
        }
    }

    .NewFoodItemsContainer {
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;

        .slick-slider {
            @include fullWidth();
        }

        .NewFoodItemsSetContainer {
            width: 33.33%;
            display: flex;
            align-items: flex-end;
            overflow: hidden;
            position: relative;

            .NewFoodItemsSet {
                width: 100%;
                min-height: 400px;
                position: relative;
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                transform: scale(1);
                transition: all ease .3s;

            }

            .NewFoodItemsName {
                width: 100%;
                padding: 20px 20px;
                background-color: rgba(0, 0, 0, 0.80);
                display: flex;
                align-items: center;
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                /* 125% */
                letter-spacing: -0.16px;
                transition: all ease .3s;
                position: absolute;
                bottom: 0;
                justify-content: center;
            }

            &:hover {
                .NewFoodItemsSet {
                    transform: scale(1.2);
                    transition: all ease .3s;
                }

                .NewFoodItemsName {
                    padding: 30px 20px;
                    transition: all ease .3s;
                }
            }
        }
    }
}

.OurCustomerContainer {
    @include fullWidth();
    padding: 30px 80px;
    padding-bottom: 100px;

    @media (max-width: 991px) {
        padding: 15px 15px;
        padding-bottom: 15px;
    }

    .slick-slider {
        @include fullWidth();
    }

    .OurCustomerSet {
        width: 260px;
        height: 260px;
        padding: 27px 27px;
        text-align: center;
        position: relative;
        background: #F6EDD9;
        border-radius: 100%;
        margin: 0 auto;

        .OurCustomerSetImg {
            img {
                width: 44px;
                height: 44px;
                border-radius: 100%;
                border: solid 2px #C58800;
                margin: 0 auto;
            }
        }

        .OurCustomerSetName {
            @include fullWidth();
            padding: 10px 0;
            color: #867719;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.16px;

            span {
                color: #555;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.13px;
            }
        }

        .OurCustomerSetContent {
            @include fullWidth();
            color: #313131;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.14px;

        }

        .UserBgImg {
            position: absolute;
            left: -12px;
            bottom: -7px;
            width: 240px;
            height: 240px;
            z-index: -1;
        }
    }
}

// Landing Page Style End

// Our Menu Page Style Start
.OurMenuPage {
    @include fullWidth();
    padding: 0 60px;
    // background-color: #F6EDD9;

    @media (max-width: 991px) {
        padding: 0 20px;
    }

    .OurMenuHeader {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 991px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 20px;
        }

        .MainTitle {
            width: auto;
            flex: 1;
            text-align: left;
        }
    }

    .MenuItemsSlider {
        padding-left: 0;
        padding-right: 0;

        .subTitle {
            @include fullWidth();
            padding-bottom: 25px;
            color: #002687;
            font-family: 'Bree Serif', serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.24px;
            margin-top: 30px;
            padding-bottom: 0;
        }

        .slick-slider {
            @include fullWidth();
        }
    }
}

// Our Menu Page Style End

// Our Stroy Style Start
.OurStoryPage {
    .subTitle {
        @include fullWidth();
        padding-bottom: 25px;
        color: #002687;
        font-family: 'Bree Serif', serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.24px;
    }

    .slick-slider {
        @include fullWidth();
    }

    .MenuItemsSlider {
        padding: 30px 80px;
        min-height: 413px;

        @media (max-width: 991px) {
            padding: 15px 15px;
        }
    }

    .OurStoryContainer {
        padding: 10px 80px;
        padding-bottom: 40px;
        border-bottom: solid 1px #E6E6E6;

        @media (max-width: 991px) {
            padding: 15px 15px;
            padding-bottom: 15px;
            padding-top: 40px;

        }

        @media (max-width: 500px) {
            padding: 15px 15px;
            padding-bottom: 15px;
            padding-top: 0;
        }
    }
}

// Our Stroy Style End

// Cart Style Start
.CartPageContainer {
    @include fullWidth();
    padding: 0 80px;
    padding-bottom: 40px;

    @media (max-width: 991px) {
        padding: 15px 15px;
        padding-bottom: 15px;
    }

    .MuiGrid-item {

        // padding-top: 0 !important;
        @media(max-width: 500px) {
            padding-top: 30px;
        }
    }

    .MainTitle {
        text-align: left;
        padding-bottom: 0;
        margin-bottom: -10px;
    }

    .Cart-left-card {
        @include fullWidth();
        border-radius: 5px;
        background: #FFF;
        box-shadow: 3px 4px 8px 0px rgba(0, 38, 135, 0.10), -3px -2px 6px 0px rgba(0, 38, 135, 0.10);

        .cart-item-set {
            width: 100%;
            padding: 30px 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: solid 1px #E6E6E6;

            @media (max-width: 991px) {
                padding: 15px 15px;
                align-items: flex-start;
                flex-direction: column;
            }

            .ClearBtn {
                padding: 0;
                color: #555;
                font-family: "Bree Serif", serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: unset;
                background-color: #fff;
            }

            .AddMoreBtn {
                padding: 0;
                display: flex;
                align-items: center;
                color: #002687;
                font-family: "Bree Serif", serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: unset;
                background-color: #fff;

                svg {
                    margin-right: 10px;
                }
            }
        }

        .cart-item-NameSet {
            position: relative;
            display: flex;
            flex-direction: column;
            padding-left: 40px;

            @media (max-width: 991px) {
                position: relative;
                display: flex;
                flex-direction: column;
                padding-left: 30px;
                align-items: flex-start;
                width: 100%;
                justify-content: space-between;
                margin-bottom: 20px;
            }

            .cart-item-Name {
                color: #002687;
                font-family: 'Bree Serif', serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media (max-width: 991px) {
                    padding-right: 15px;
                }
            }

            .cart-item-customise {
                display: flex;
                align-items: center;
                color: #313131;
                font-family: Josefin Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 15px;

                @media (max-width: 991px) {
                    margin-top: 6px;
                }

                svg {
                    margin-left: 10px;
                    cursor: pointer;
                }
            }

            .square1 {
                content: '';
                width: 18px;
                height: 18px;
                // border: solid 1px #009525;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5px;
                border-radius: 4px;
                position: absolute;
                left: 0;
                top: 4px;

                .dot1 {
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    // background-color: #00a912;
                    position: absolute;
                    left: 2px;
                    top: 2px;
                }
            }


            &.nonveg {
                .square1 {
                    border: solid 2px #7D0101;
                }

                .dot1 {
                    background-color: #7D0101;
                }
            }
        }

        .cartCoutCostSet {
            display: flex;
            align-items: center;

            @media (max-width: 991px) {
                width: 100%;
                justify-content: space-between;
                padding-left: 30px;
            }

            .cart-item-cast {
                color: #313131;
                font-family: "Bree Serif", serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                min-width: 80px;
                text-align: right;
                line-height: 27px;
            }
        }

        .MuiButtonGroup-outlined {
            border-radius: 5px;
            border: 1px solid #E6E6E6;
            background: #FFF;

            .MuiButton-outlined {
                height: 40px;
                border: none;
                background-color: #fff;
                color: #009506;
                font-family: "Bree Serif", serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                svg {
                    path {
                        stroke: #009506;
                    }
                }
            }
        }
    }

    .BillDetailsCard {
        @include fullWidth();
        border-radius: 5px;
        border: 1px solid #E6E6E6;
        background: #FFF;

        .SubTitle {
            @include fullWidth();
            padding: 30px 30px;
            padding-bottom: 10px;
            color: #002687;
            font-family: 'Bree Serif', serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.24px;
        }

        .BillDetailsSet {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px 30px;
            color: #313131;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-bottom: solid 1px #E6E6E6;

            @media (max-width: 991px) {
                padding: 15px 15px;
            }

            .BillCost {
                color: #313131;
                font-family: 'Bree Serif', serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                &.textBlue {
                    color: #002687;
                }
            }
        }

        .FirstOrderSet {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px 30px;
            border-bottom: solid 1px #E6E6E6;

            .FirstOrderTop {
                width: 100%;
                display: flex;
                align-items: center;
                color: #313131;
                font-family: Bree Serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;

                span {
                    padding: 0 12px;
                }
            }

            .FirstOrderBottom {
                width: 100%;
                color: #555;
                font-family: Josefin Sans;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 15px;
            }

            .FirstOrderRight {
                .MuiButton-textPrimary {
                    color: #009506;
                    font-family: Bree Serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: none;
                    background-color: #fff;
                }
            }
        }
    }

    .PersonalInfoForm {
        @include fullWidth();
        padding: 30px 30px;
        border-bottom: solid 1px #E6E6E6;

        @media (max-width: 991px) {
            padding: 15px 15px;
        }

        .FormControl {
            @include fullWidth();
            border-radius: 5px;
            border: 1px solid #E6E6E6;
            background: #FFF;

            .MuiOutlinedInput-notchedOutline {
                border-color: transparent;
            }

            .MuiInputBase-input {
                padding: 10.2px 14px;
                color: #313131;
                font-family: Josefin Sans;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                align-items: center;
            }

            .MuiFormLabel-root {
                color: #313131;
                font-family: Josefin Sans;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                &.MuiInputLabel-shrink {
                    background-color: #fff;
                    transform: translate(14px, -6px) scale(0.85);
                }
            }

            legend {
                color: #313131;
                font-family: Josefin Sans;
                font-size: 13px;
            }
        }

        .css-dmmspl-MuiFormGroup-root {
            display: flex;
            flex-direction: row;

            .PrivateSwitchBase-input {
                width: 16px;
                height: 16px;
            }

            .css-1hbvpl3-MuiSvgIcon-root,
            .css-11zohuh-MuiSvgIcon-root {
                width: 16px;
                height: 16px;
                color: #C58800;
            }

            .css-j204z7-MuiFormControlLabel-root {
                margin: 0;
                margin-right: 20px;

                @media (max-width: 991px) {
                    margin: 5px 0;
                    margin-right: 20px;
                }

                .MuiRadio-colorPrimary {
                    padding: 0;
                    margin-right: 8px;

                    &.Mui-checked {
                        &+.MuiTypography-root {
                            color: #000;
                            font-weight: 500;
                        }
                    }
                }

                .MuiTypography-root {
                    color: #555;
                    font-family: Josefin Sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: -0.14px;
                }
            }

        }

        .subTitle {
            @include fullWidth();
            color: #002687;
            font-family: Bree Serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

.FormControl.addressField {
    .MuiIconButton-sizeMedium {
        padding-right: 0;
        padding-left: 15px;
        border-left: solid 1px #E6E6E6;
        border-radius: 0;
    }
}

// Cart Style End

// Track Order Style Start
.TrackOrderCard {
    @include fullWidth();
    border-radius: 5px;
    background: #FFF;
    box-shadow: 3px 4px 8px 0px rgba(0, 38, 135, 0.05), -3px -2px 6px 0px rgba(0, 38, 135, 0.05);
    margin: 50px 0;

    @media (max-width: 991px) {
        margin: 0;
        box-shadow: none;
    }

    .TrackOrderImg {
        @include fullWidth();
        padding: 80px;
        padding-top: 0;
        border-bottom: solid 1px #E6E6E6;

        svg {
            width: 100%;
            height: auto;
        }

        @media (max-width: 991px) {
            padding: 15px 15px;
        }

        .title {
            @include fullWidth();
            padding: 15px 0;
            color: #009506;
            font-family: Bree Serif;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.28px;
            text-align: center;
        }

        .content {
            @include fullWidth();
            color: #313131;
            font-family: Josefin Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding-bottom: 30px;
        }
    }

    .TrackOrderFooter {
        @include fullWidth();
        padding: 30px 30px;
        text-align: center;

        .content {
            @include fullWidth();
            color: #555;
            font-family: Josefin Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
            margin-bottom: 20px;
        }
    }
}

.DelivaryBtnGroup {
    width: 100%;
    float: left;
    border-radius: 5px;
    border: 1px solid #C58800;
    background: #FFF;

    .MuiFormControlLabel-root {
        margin: 0 !important;
        width: 50%;
        text-align: center;

        .MuiRadio-colorPrimary {
            display: none;

            &.Mui-checked {
                &+.MuiTypography-root {
                    background-color: #C58800;
                    color: #fff !important;
                }
            }
        }

        .MuiTypography-root {
            width: 100%;
            padding: 14px 0;
        }
    }
}

// Track Order Style End
#apple-pay-button {
    height: 48px;
    width: 100%;
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: plain;
    -apple-pay-button-style: black;
}

.footerTable {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 991px) {
        padding-left: 15px;
    }

    .css-1ex1afd-MuiTableCell-root {
        border-bottom: none;
    }

    .css-1q1u3t4-MuiTableRow-root {
        border-bottom: solid 1px #E6E6E6;
    }
}


.contactUsPage {
    @include fullWidth();
    padding: 40px 80px;

    @media (max-width: 991px) {
        padding: 15px 15px;
    }

    .css-mhc70k-MuiGrid-root>.MuiGrid-item {
        padding-top: 0;
    }

    h1 {
        font-size: 34px;
        font-family: 'Bree Serif', serif;
        font-weight: 400;

        @media (max-width: 991px) {
            margin: 15px 0;
            font-size: 24px;
        }
    }

    .footerTable {
        .MuiTableContainer-root {
            width: 100%;
            box-shadow: none;
            border: solid 1px #E6E6E6;

            .MuiTable-root {
                th {
                    font-family: 'Bree Serif', serif;
                }

                tbody {
                    td {
                        font-family: 'Josefin Sans', sans-serif;
                    }

                    tr:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }

    .subscribeUsCard {
        @include fullWidth();
        border-radius: 5px;
        background: #FFF;
        box-shadow: 3px 4px 8px 0px rgba(0, 38, 135, 0.10), -3px -2px 6px 0px rgba(0, 38, 135, 0.10);

        .subscribeBody {
            @include fullWidth();
            padding: 20px 20px;

            .title {
                @include fullWidth();
                color: #005466;
                font-family: 'Bree Serif', serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.18px;
            }

            .content {
                @include fullWidth();
                padding: 15px 0;
                color: #555;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                /* 125% */
                letter-spacing: -0.16px;
            }

            .formContainer {
                @include fullWidth();
                padding: 10px 0;

                .MuiFormControl-root {
                    margin-bottom: 20px;

                    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
                        height: 48px;
                    }

                    .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
                        color: #A3A3A3;
                        font-family: Josefin Sans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.14px;
                    }

                    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
                        padding: 12px 16px;
                        font-family: Josefin Sans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.14px;
                        color: #000;

                    }

                    .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
                        &.Mui-focused {
                            color: #A3A3A3;
                        }
                    }

                    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
                        border-color: #E6E6E6;
                    }
                }

                .MuiButton-textPrimary.submitBtn {
                    @include fullWidth();
                    padding: 15px 10px;
                    border-radius: 5px;
                    background-color: #C58800 !important;
                    border: solid 1px #C58800;
                    color: #FFF;
                    font-family: Josefin Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: -0.14px;
                    text-transform: inherit;
                }
            }
        }

        .subscribeFooter {
            width: 100%;
            display: flex;
            border-top: solid 1px #E6E6E6;

            @media (max-width: 991px) {
                flex-direction: column;
            }

            .subscribeFooterSet {
                display: flex;
                flex: 1;
                padding: 20px 30px;
                flex-direction: column;
                border-right: solid 1px #E6E6E6;

                @media (max-width: 991px) {
                    padding: 15px 15px;
                    flex-direction: row;
                    align-items: center;
                    border-right: none;
                    border-bottom: solid 1px #E6E6E6;
                }

                &:last-child {
                    border-right: none;
                }

                .FooterContent {
                    color: #555;
                    padding-top: 15px;
                    font-family: Josefin Sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    /* 125% */
                    letter-spacing: -0.16px;
                    word-break: break-all;

                    @media (max-width: 991px) {
                        padding-top: 0;
                        padding-left: 15px;
                    }
                }
            }
        }
    }
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #97840a !important;
    text-align: right;
}

.MuiFormControlLabel-root {
    text-align: right;
    display: flex;
    justify-content: flex-start !important;
}

.css-ahj2mt-MuiTypography-root {
    text-align: right !important;
}

.show-addons {
    padding: 10px 10px 10px 0px;
    opacity: 0.5;
}

.mainContainer {
    margin: 0 80px 0 80px;
    background-color: #FFFCF4;
    margin-top: 60px;
    padding: 30px;
    margin-bottom: 60px;

}

.verityTittleTab {
    display: flex;
    flex-direction: row;
    padding-left: 12px;

}

.itemContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    // border: 1px solid black;
}

.squareBox {
    border: solid 1.5px #009506;
    border-radius: 3px;
    width: 15px;
    height: 15px;
    padding: 1px 2px 1px 2.5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot2 {
    height: 7px;
    width: 7px;
    background-color: #009506;
    border-radius: 50%;
    display: inline-block;
}

.ButtonCount {
    color: #C58800;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-color: transparent;
}

.cardItem {
    display: flex;
    justify-content: space-between;

}

.desc {
    color: #555555;
    font-size: 12px;
    font-weight: 400;
    font-style: 'Josefin Sans';
}

.amount {
    margin-right: 20px;
    color: #007705;
    font-size: 18px;
    font-weight: 400;
    font-family: Bree Serif;

}

#addBtn {
    border-color: transparent;
    color: #C58800;
    text-transform: 'lowercase';
    text-transform: 'inherit';
    font-family: 'Josefin Sans', sans-serif;
}

.css-ahj2mt-MuiTypography-root:after {
    // content: '';
    content: "";
    position: absolute;
    bottom: -10.3px;
    left: 0;
    right: 0;
    height: 3px;
    background: #C58800;

}

.cardContainer {
    width: 50%;
    padding: 20px;
    border-bottom: 0.5px solid #E1E1E1;
    border-right: 0.5px solid #E1E1E1;

    @media(max-width: 991px) {
        width: 100%;
    }
}

.slick-slide {
    padding-left: 25px;
}

.ImgourStory {

    width: 550px;
    height: 500px;
    margin-right: 20px;
    margin-top: 30px;

    @media(max-width: 991px) {
        width: 550px;
        height: 500px;
        margin-right: 20px;
        margin-top: 30px;


    }

    @media (max-width: 1188px) {
        // margin-left: 130px;
        margin-top: 30px;
    }
}

.imgContainer {

    @media(max-width: 991px) {
        // width: 500px;
        // height: 500px;
        margin-right: 20px;

    }

    @media(max-width: 550px) {

        display: none;
    }
}

.HeaderContainer.homeHeader {
    @media(max-width: 800px) {
        border-bottom: none;
    }
}

.OurStoryContainer {

    @media (max-width: 471px) {
        // padding: 15px 15px;
        // padding-bottom: 15px;
        padding-top: 0 !important;
    }
}

.css-11reh94-MuiGrid-root>.MuiGrid-item {
    @media (max-width: 550px) {
        padding: 15px 15px;
        // padding-bottom: 15px;
        padding-top: 30px !important;
    }

}

.css-mhc70k-MuiGrid-root {
    @media (max-width: 550px) {
        margin-top: 10px !important;
    }
}

.arrowBtnContainer {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 40px;
    padding: 0px 15px 0px 15px;
    margin: 0px;

    @media (max-width: 550px) {
        display: flex;
        justify-content: space-between;
        position: relative;
        top: 30px;
        padding: 0px 5px 0px 5px;
        margin: 0px;
    }

}

.scrollBtn {
    padding-left: 70px;
    padding-right: 70px;

    @media (max-width: 550px) {
        padding-left: 45px;
        padding-right: 45px;
    }
}

.arrowIcon {
    width: 30px;
    height: 30px;

    @media (max-width: 550px) {
        width: 20px;
        height: 20px;
    }

    @media (max-width: 991px) {
        width: 25px;
        height: 25px;
    }

}

.menuPageHeading {
    display: flex;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 15px;

    @media (max-width: 991px) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    @media (max-width: 767px) {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.headingImg {
    width: 60%;
    height: 50px;
    object-fit: contain;
    @media (max-width: 991px) {
        height: 40px;
    }
    @media (max-width: 767px) {
        height: 40px;
        object-fit: contain;
        width: 68%;
    }
    
}

// .css-j204z7-MuiFormControlLabel-root{

// }
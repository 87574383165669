.FooterContainer {
    width: 100%;
    float: left;
    padding: 30px 80px;
    background-color: #F2FCEE;
    @media (max-width: 991px) {
        padding: 15px 15px;
    }
    .FooterLeft {
        width: 50%;
        float: left;
        padding-right: 150px;
        position: relative;
        @media (max-width: 991px) {
            width: 100%;
            padding-right: 0px;
        }
        .FooterMenu {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: solid 1px #D3D3D3;
            .MuiButton-textPrimary{
                padding: 15px 15px;
                color: #313131;
                font-family: 'Josefin Sans', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: unset;
            }
        }
        .FooterAddress {
            width: 100%;
            float: left;
            padding: 30px 0;
            border-bottom: solid 1px #D3D3D3;
            @media (max-width: 991px) {
                border-bottom: none;
            }
            p {
                color: #313131;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0;
                &:nth-child(2) {
                    padding-top: 24px;
                    padding-bottom: 18px;
                }
            }
        }
        .FooterCopyRights {
            width: 100%;
            float: left;
            padding-top: 30px;
            padding-bottom: 10px;
            color: #313131;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            @media (max-width: 991px) {
                display: none;
            }
        }
        .FooterBgImg {
            position: absolute;
            right: 0px;
            bottom: -30px;
            height: 112px;
        }
    }
    .FooterRight {
        width: 50%;
        float: left;
        padding-left: 25px;
        @media (max-width: 991px) {
            width: 100%;
            padding-left: 0;
        }
        iframe {
            width: 100%;
            height: 260px;
            border-radius: 4px;
            border: solid 1px transparent;
        }
        .FooterCopyRights {
            display: none;
            padding-top: 15px;
            color: #313131;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            @media (max-width: 991px) {
                display: block;
            }
        }
    }
}



@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Josefin+Sans:wght@300;400;500;600;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  // font-family: 'Bree Serif', serif;
}


/* loader.css */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* This makes the loader cover the entire viewport height */
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.loader-img {
  width: 100px;
  height: 100px;
  background: #fff;
  border-radius: 100%;
  padding: 20px;
}

.redcolor {
  color: #9a0a0a;
}

li.MuiMenuItem-root.menuItem {
  border-bottom: solid 1px #E6E6E6;
}

.MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedEnd css-1bn53lx{
  height: 45px;
}

.css-1bn53lx {
  height: 45px !important;
}

// .slick-list ::before {
//   display: flex;
//   justify-content: center;
// }
// .slick-list p {
//   padding-left: 20px;
// }

.slick-list{
  margin: 0px 2px;
}